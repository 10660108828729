import React, {useContext} from 'react';
import { GlobalDataContext } from "../context/context";
import Navbar from './global/navbar';
import Banner from './home-components/banner';
import Values from './about-components/values';
import HomeBlockOne from './home-components/home-block';
import RecentProjects from './home-components/recent-projects-home';
import CallToAction from './home-components/call-to-action';
import Directories from './home-components/directories';
import HomeServices from './home-components/home-services';
import Footer from './global/footer';
import ModalHome from "./home-components/ModalComponent"
import VideoPromocional from "./home-components/VideoPromocional"
import HomeAbout from './about-components/block-about';
import Map from './contact-components/map';

const Home_V1 = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <Banner />
        <Values />
        <HomeBlockOne />
        <CallToAction />
        <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
        <HomeAbout/>
        <Directories />
        <RecentProjects />
        <HomeServices />
        <ModalHome />
        <Map />
        <Footer />
    </div>
}

export default Home_V1

