import React, {useContext} from 'react'
import { GlobalDataContext } from '../../context/context'
const VideoPromocional = ({ linkVideo, image, title, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    <h3 className='text-white'>{title}</h3>
                </div>
                <iframe
                    title="Promotional video"
                    className='video_promo'
                    id="ytplayer"
                    type="text/html"
                    src={`https://www.youtube.com/embed/${linkVideo}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo}`}
                    frameborder="0" allowfullscreen />
            </div>
        </div>
    )
}
export default VideoPromocional